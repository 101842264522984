$green: #49A32A;

body {

}

a{
  color: $green;

  &:hover{
    color: darken($green, 10%);
  }
}


#loader_wrapper {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fffff3;
  z-index: 100;
}

#loader {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  text-align: center;
  color: #666;
  z-index: 110;

  p{
    margin-top: 20px;
  }
}


/* ---------------------------------------------------
  ヘッダー
  ---------------------------------------------------- */
header{
  background: #fff;
  padding: 5px;

  img{
    height: 40px;
  }
}

@if $type == xs{
  header img{
    height: 30px;
  }
}


/* ---------------------------------------------------
  メインのイメージ
  ---------------------------------------------------- */
.main_wrapper{
  position: relative;
  height: 520px;
  width: 100%;
  overflow: hidden;
}

.main_particle{
  background: #408421;
  @include gradient(#408421, #A8CF53, vertical);
  width: 100%;
  overflow: hidden;

  canvas{
    width: 1024px;
    height: 520px;
    display: block;
    opacity: 0.35;
    margin: 0 auto;
  }
}


.main_text{
  position: absolute;
  top: 110px;
  left: 50%;
  margin-left: -200px;
  width: 400px;

  h1{
    color: #fff;
    text-align: center;
    font-size: 76px;
    //font-weight: 700;
    letter-spacing: 0.1em;
    text-shadow: 0px 0px 4px rgba(0, 30, 0, 0.4);
    /* 3秒かけてアニメーションする */
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    font-family: "Lucida Grande", "segoe UI", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Meiryo, Verdana, Arial, sans-serif;

    span:nth-child(1){
      font-weight: 700;
      font-family: 'Vollkorn', serif;
      font-size: 80px;
      letter-spacing: 0.2em;
    }
    span:nth-child(2){
      font-size: 50px;
      margin-left: 0.12em;
      margin-right: 0.10em;
    }
  }

  h2{
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 4px rgba(0, 30, 0, 0.4);
    line-height: 200%;
    font-size: 25px;
    margin-top: 20px;
    /* 2.5秒かけてアニメーションする */
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    /* 1秒待ってからアニメーションする */
    -webkit-animation-duration: 1s;
    animation-delay: 1s;
  }
}


@if $type == xs{

  .main_particle{

    canvas{
      left: 50%;
      margin-left: -512px;
      position: relative;
    }
  }

  .main_text{
    top: 120px;
    width: 100%;
    margin-left: -50%;

    h1{
      font-size: 65px;
      letter-spacing: 0.08em;

      span:nth-child(1){
        letter-spacing: 0.15em;
      }
      span:nth-child(2){
        margin-left: 0.03em;
        margin-right: 0.05em;
      }
    }

    h2{
      font-size: 20px;
      line-height: 180%;
      font-weight: 700;
    }
  }
}


/* ---------------------------------------------------
  下にスクロールボタン
  ---------------------------------------------------- */
.main_down{
  position: absolute;
  bottom: 30px;
  text-align: center;
  margin: 0 auto;
  width: 100%;

  > div{
    width: 46px;
    margin: 0 auto;
  }
  a {
  	padding-top: 60px;
    display: block;
  }
  a span {
  	position: absolute;
  	top: 0;
  	left: 50%;
  	width: 46px;
  	height: 46px;
  	margin-left: -23px;
  	border: 1px solid #fff;
  	border-radius: 100%;
  	box-sizing: border-box;
  }
  a span::after {
  	position: absolute;
  	top: 50%;
  	left: 50%;
  	content: '';
  	width: 16px;
  	height: 16px;
  	margin: -12px 0 0 -8px;
  	border-left: 1px solid #fff;
  	border-bottom: 1px solid #fff;
  	-webkit-transform: rotate(-45deg);
  	transform: rotate(-45deg);
  	box-sizing: border-box;
  }
  a span::before {
  	position: absolute;
  	top: 0;
  	left: 0;
  	z-index: 10;
  	content: '';
  	width: 44px;
  	height: 44px;
  	box-shadow: 0 0 0 0 rgba(255,255,255,0.1);
  	border-radius: 100%;
  	opacity: 0;
  	-webkit-animation: sdb 3s infinite;
  	animation: sdb 3s infinite;
  	box-sizing: border-box;
  }
}
@-webkit-keyframes sdb {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	60% {
		box-shadow: 0 0 0 40px rgba(255,255,255,0.1);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes sdb {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	60% {
		box-shadow: 0 0 0 40px rgba(255,255,255,0.1);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}


/* ---------------------------------------------------
  タイトル下
  ---------------------------------------------------- */
.lead_wrapper{
  padding: 40px 0;
  //border-bottom: 1px solid #eaeae0;

  p{
    font-size: 18px;
    text-align: center;
    line-height: 180%;
    padding: 0 15px;
  }
}

@if $type == xs{
  .lead_wrapper{
    p{
      text-align: left;
      font-size: 16px;
    }
  }
}

/* ---------------------------------------------------
  タイトル下共通
  ---------------------------------------------------- */
.info_wrapper h1,
.company_wrapper h1,
.service_wrapper h1{
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  color: #000;

  &::after{
    content: "";
    width: 65px;
    display: block;
    height: 1px;
    border-bottom: 4px solid $green;
    text-align: center;
    margin: 10px auto 0;
  }
}


/* ---------------------------------------------------
  お知らせ
  ---------------------------------------------------- */
.info_wrapper{
  padding: 30px 15px;
  //background: #FCFCE7;
  border-top: 1px solid #eee;

  .title{
    text-align: center;

    i{
      font-size: 40px;
      color: $green;
      display: block;
    }

    h1{
      font-size: 25px;
      margin-top: 0.5em;
      margin-bottom: 0;
      //background: $green;
      border-radius: 3px;
      padding: 4px 20px;
      //color: #fff;
      color: #000;
      width: auto;
      display: inline-block;
    }
  }

  ul{
    list-style: none;
    padding-left: 0;
    margin-top: 20px;

    li{
      font-size: 16px;
      //border-bottom: 2px dashed $green;
      padding: 5px 0;
      line-height: 160%;
    }
  }
}


@if $type == xs{

  .info_wrapper{

    .info_bg{
      padding: 20px;
    }

    .title{

      h1{
        display: block;
        margin-bottom: 1em;
      }
    }
  }
}


/* ---------------------------------------------------
  会社概要
  ---------------------------------------------------- */
.company_wrapper{
  padding: 30px 5px;

  table.table{

    th, td{
      border-color: #eee;
    }

    ul{
      padding-left: 20px;
    }
  }


}

@if $type == xs{

  .company_wrapper{
      background: #F4FFED;

    table.table{

      th, td{
        display: block;
        border: none;
      }

      th{
        color: $green;
        //border-top: none;
      }

      td{
        border-top: none;
      }
    }
  }
}


/* ---------------------------------------------------
  サービス
  ---------------------------------------------------- */
.service_wrapper{
  padding: 30px 0;
  //background: #F4FFED;

  h2{
    text-align: center;
  }

  h2{
    color: $green;
    font-size: 24px;
  }

  p{
    padding: 20px 15px;
  }
  .service_image1,
  .service_image2{
    display: block;
    height: 160px;
    margin: 10px auto;
  }

  i{
    color: $green;
    color: #333;
  }

  .fa-stack-3x{
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .service_image1{
    width: 170px;

    .fa-lg{
      font-size: 4em;
    }
    .fa-stack-3x{
      font-size: 2.8em;
    }
    .fa-stack-1x{
       left: 0.5em;
       top: 0.25em;
     }
   }

  .service_image2{
    width: 230px;

    .fa-lg{
      font-size: 4em;
    }
    .fa-stack-3x{
      font-size: 3em;
    }
    .fa-stack-1x{
      top: 0.6em;
      font-size: 0.8em;
    }
  }
}


@if $type == xs{

  .service_wrapper{

    .service_image1{
      width: 130px;
    }

    .service_image2{
      width: 175px;
    }

    .service_image1,.service_image2{
      height: 130px;
    }

    .service_image1 .fa-lg,
    .service_image2 .fa-lg{
      font-size: 3em;
    }

  }
}


/* ---------------------------------------------------
  フッター
  ---------------------------------------------------- */
footer{
  text-align: center;
  padding: 30px;
  background: #eee;

  p{
    color: #888;
  }

  a.social_icon i{
    font-size: 30px;
    color: #888;

    &:hover{
      color: #555;
    }
  }
}


@if $type == xs{
  footer{
    padding: 20px 10px;
  }
}
