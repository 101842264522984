$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "flex";
@import "gradient";
@import "font-awesome";
@import "animate";

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

// mixin
// ベンダープレフィックス
@mixin css3($property, $value) {
   @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
     #{$prefix}#{$property}: $value;
   }
}

@mixin fontawesome($content){
  content: $content;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin clear{
  &::after{
    content: "";
    display: block;
    clear: both;
  }
}

//デバイス定義
$type: xs sm md;
@import "style";

/* スマホ
-------------------------------------------------- */
@media only screen and (max-width: 767px) {
  $type: xs;
  @import "style";
}

/* タブレット
-------------------------------------------------- */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  $type: sm;
  @import "style";
}

/* PC
-------------------------------------------------- */
@media only screen and (min-width: 992px) {
  $type: md;
  @import "style";
}


/* 例 */
.aaa{
  @if $type == all { //全端末共通

  } @else if $type==xs or $type==sm or $type==md { //この内部はメディアクエリーで分岐

      @if $type == xs or $type == sm { //スマホとタブレット
        width: 100%;
      }

      @if $type == xs { //スマホ

      } @else if $type == sm { //タブレット

      } @else if $type == md { //PC
      }
    }
}
